<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <h1>{{ $t('Calendar.pageTitle') }}</h1>
        <div class="mb-12">
          <core-filters
            :active-filters-count="activeFiltersCount"
            module="calendar"
            section="filters"
            class="mb-2"
            @update="onFilterUpdate()"
          />
          <core-filter-buttons
            @apply="onFilterUpdate()"
            @reset="onFilterReset()"
            :reset-label="$t('Calendar.resetLabel')"
          />
        </div>
        <v-card class="pa-4">
          <div class="d-flex flex-wrap mb-3">
            <core-ical-button
              v-if="hasPermission(permissions.ICS)"
              class="mb-2 mr-3"
              :loading="loading"
            >
              <template v-slot:button-content>
                <v-icon x-small class="mr-1">
                  $vuetify.icons.calendarPlus
                </v-icon>
                {{ $t('Calendar.iCal') }}
              </template>
            </core-ical-button>
            <v-spacer />
            <div class="d-flex flex-wrap align-center">
              <core-calendar-today-button
                :loading="loading"
                @click="onTodayClick"
                class="mb-2 mr-3"
              />
              <core-calendar-type-toggle
                v-model="type"
                :loading="loading"
                :enabled-types="['day', 'week', 'month']"
                class="mb-2"
              />
            </div>
          </div>
          <core-calendar
            :events="events"
            :loading="loading"
            :type="type"
            v-model="current"
            @range-change="onRangeChange"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { today } from '@/utils/dateHelper';

export default {
  metaInfo () {
    return {
      title: this.$t('Calendar.pageTitle'),
    }
  },
  data: () => ({
    type: null, // initialize type with null to avoid second api call when switching type in mounted() hook
    current: today(),
    start: '',
    end: '',
  }),
  async mounted () {
    this.type = this.$vuetify.breakpoint.xs ? 'day' : 'month'
    await this.getFilterItems()
  },
  computed: {
    ...mapGetters('calendar', [
      'events',
      'loading',
      'activeFiltersCount',
    ]),
  },
  methods: {
    ...mapActions('calendar',
      [
        'getEvents',
        'getFilterItems',
      ],
    ),
    onTodayClick () {
      this.current = today()
    },
    onRangeChange (start, end) {
      this.start = start
      this.end = end
      this.getEvents({ start: this.start, end: this.end })
    },
    onFilterUpdate () {
      this.getEvents({ start: this.start, end: this.end })
    },
    onFilterReset () {
      this.$store.commit('calendar/RESET_FILTER_VALUES')
      this.$store.commit('calendar/SET_FILTER_EXPANDED', undefined)
      this.$store.dispatch('auth/resetSettings', { module: 'calendar' }, { root: true })
      this.getEvents({ start: this.start, end: this.end })
    },
  },
}
</script>
